<template>
  <v-app>
    <v-app-bar
      app
      color="red accent-4"
      dark
      shrink-on-scroll
      fade-img-on-scroll
      flat
      :src="require('./assets/barra-pasto.jpg')"
    >
      <v-row align="center">
        <v-toolbar-title>
          Formulario C19 Ariztia
        </v-toolbar-title>
      </v-row>
      <v-img
        contain
        max-width="110"
        max-height="300"
        :src="require('./assets/logo.svg')"
      ></v-img>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
    <v-footer color="red accent-4" dark padless>
      <v-col class="text-center" cols="12">
        <strong>Ariztía - Depto. Mantenimiento</strong>
        <br />{{ new Date().getFullYear() }} — v1.12.11_β —
        <a
          href="mailto:pegutierrez@ariztia.com?subject=Contacto web C19-Ariztia"
          style="color:white;"
          >@BenAliSoft</a
        >
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>
